<template>
	<v-col cols="12">
		<v-card class="pa-4" outlined>
			<div class="text-h5 mb-2">Order summary</div>

			<v-card-text class="">

				<v-alert type="warning" class="mb-4" v-if="vaseCount !== bodyCount">
					The number of vases ({{ vaseCount }}) and bodies ({{ bodyCount }}) is different!
				</v-alert>

				<v-data-table :headers="headers" :items="products" item-key="name" class="text-body-1"
					:items-per-page="-1" hide-default-footer mobile-breakpoint="0">
					<template v-slot:item="{ item, headers }">
						<tr v-if="item.type === 'category'">
							<td :colspan="headers.length" :class="`id-${item.id}`">
								<div class="text-h6" v-html="item.name" />
							</td>
						</tr>
						<tr v-if="item.type === 'product'">
							<td class="font-weight-500">
								{{ item.name }}
							</td>
							<td class="font-weight-500 text-no-wrap text-right"
								v-if="['active', 'coming_soon'].includes(item.status.slug)">
								<div
									:style="item.retailPrice.promotional_price ? 'text-decoration: line-through' : ''">
									{{ item.retailPrice.price / 100 | euro }}
								</div>

								<div v-if="item.retailPrice.promotional_price">
									{{ item.retailPrice.promotional_price / 100 | euro }}
								</div>
							</td>

							<td class="text-right text-no-wrap" v-if="['active', 'coming_soon'].includes(item.status.slug)">
								<div
									:style="item.prices[activeDl].promotional_price ? 'text-decoration: line-through' : ''">
									{{ item.prices[activeDl].price / 100 | euro }}
								</div>

								<div v-if="item.prices[activeDl].promotional_price">
									{{ item.prices[activeDl].promotional_price / 100 | euro }}
								</div>
							</td>

							<td class="text-right" v-if="['active', 'coming_soon'].includes(item.status.slug)">
								{{ item.quantity }}
							</td>
							<td class="text-right font-weight-700 text-no-wrap text-right"
								v-if="['active', 'coming_soon'].includes(item.status.slug)">
								<div
									:style="item.prices[activeDl].promotional_price ? 'text-decoration: line-through' : ''">
									{{ item.prices[activeDl].price * item.quantity / 100 | euro }}
								</div>

								<div v-if="item.prices[activeDl].promotional_price">
									{{ item.prices[activeDl].promotional_price * item.quantity / 100 | euro }}
								</div>
							</td>

							<td class="font-weight-500 red--text"
								v-if="!['active', 'coming_soon'].includes(item.status.slug)" colspan="4">
								Product unavailable. Please contact the support at <a class="font-weight-bold"
									href="mailto:wholesale@wookah.com">wholesale@wookah.com</a>
							</td>
						</tr>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-col>
</template>
<script>

export default {
	name: 'BlockProducts',
	components: {},
	computed: {
		products() {
			return this.$store.getters['BASKET_STORE/PRODUCTS'];
		},
		bodyCount() {
			let products = this.products.filter(i => i.firstCategoryId === this.config.body);
			let count = 0;
			for (let i = 0; i < products.length; i++) {
				count += products[i].quantity;
			}
			return count;
		},
		vaseCount() {
			let products = this.products.filter(i => i.firstCategoryId === this.config.vase);
			let count = 0;
			for (let i = 0; i < products.length; i++) {
				count += products[i].quantity;
			}
			return count;
		},

		config() {
			return this.$store.getters['BASKET_STORE/CONFIG'];
		},

		activeDl() {
			return this.$store.getters['BASKET_STORE/ACTIVE_DL'];
		},
	},
	data: () => ({

		headers: [
			{
				text: 'Product', value: 'name', sortable: false, align: 'left'
			},
			{
				text: 'Retail Price (23% tax incl.)', value: 'retail', sortable: false, align: 'right'
			},
			{
				text: 'Your Price (net)', value: 'your', sortable: false, align: 'right',
			},
			{
				text: 'Quantity', value: 'quantity', sortable: false, width: 140, align: 'right',
			},
			{
				text: 'Total', value: 'total', sortable: false, width: 120, align: 'right',
			},
		],
	}),
	methods: {},
	created() {
	}
};
</script>
